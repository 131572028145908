import {
  USER_GET_USERS_REQUEST,
  USER_GET_USERS_SUCCESS,
  USER_GET_USERS_FAIL,
  USER_GET_USER_REQUEST,
  USER_GET_USER_SUCCESS,
  USER_GET_USER_FAIL,
  POST_LOGINAS_REQUEST,
  POST_LOGINAS_SUCCESS,
  POST_LOGINAS_FAIL,
} from "../actions/user";

const initialState = {
  users: null,
  postLoginAsSuccess: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_GET_USERS_REQUEST:
      return {
        ...state,
        getUsersRequest: true,
        getUsersSuccess: false,
      };
    case USER_GET_USERS_SUCCESS:
      return {
        ...state,
        getUsersRequest: false,
        getUsersSuccess: true,
        users: action.data
      };
    case USER_GET_USERS_FAIL:
      return {
        ...state,
        getUsersRequest: false,
        getUsersSuccess: false,
      };
    case USER_GET_USER_REQUEST:
      return {
        ...state,
        getUserRequest: true,
        selectedUser: null
      };
    case USER_GET_USER_SUCCESS:
      return {
        ...state,
        getUserRequest: false,
        selectedUser: action.data
      };
    case USER_GET_USER_FAIL:
      return {
        ...state,
        getUserRequest: false,
        selectedUser: null
      };
    case POST_LOGINAS_REQUEST:
      return {
        ...state,
        postLoginAsRequest: true,
        postLoginAsSuccess: false,
      };
    case POST_LOGINAS_SUCCESS:
      return {
        ...state,
        postLoginAsRequest: false,
        facadeUser: action.data,
        postLoginAsSuccess: true,
      };
    case POST_LOGINAS_FAIL:
      return {
        ...state,
        postLoginAsRequest: false,
        postLoginAsSuccess: false,
      };
    default:
      return state;
  }
};

export default user;