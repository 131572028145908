import React from "react";
import { Card, Row, Col, CardHeader, Button, CardBody, Alert } from "reactstrap";
import { formatCurrency } from "../../utils/digit";
import classnames from "classnames";


class OrderCard extends React.Component {



  isDelayedRequest = () => {
    if (!this.props.selectedOrder.request_at) return false;
    return this.props.selectedOrder.request_at === this.props.selectedOrder.created_at ? false : true;
  };

  renderCurbsideInfo = () => {
    if (!this.props.selectedOrder.curbside_info) return null;
    return (
      <div className="odsh-curbside">
        <span className="make">{this.props.selectedOrder.curbside_info.vehicle_make}, </span>
        <span className="type">{this.props.selectedOrder.curbside_info.vehicle_type}, </span>
        <span className="color">
          {this.props.selectedOrder.curbside_info.vehicle_color}{" "}
          <i className={"fas fa-stop " + this.props.selectedOrder.curbside_info.vehicle_color}></i>
        </span>
      </div>
    );
  };

  render() {


    if (this.props.selectedOrder == null) {
      return (
        <Card className="main-card">
          <CardBody>
            <div className="od-order">
              <h3 className="text-success">
                <i className="fal fa-spinner fa-spin mr-2"></i>Loading...
        </h3>
            </div>
          </CardBody>
        </Card>
      )

    } else {
      let selectedOrder = this.props.selectedOrder;

      return (

        <Card className="main-card">
          <CardHeader className="dark">
            <Row>
              <Col>
                <Button
                  color="link d-flex align-items-center p-0"
                  onClick={this.props.goBack}
                  font-size="16px"
                  font-weight="600"
                >
                  <i className="fal fa-angle-left fa-2x mr-2"></i>BACK
                </Button>
              </Col>
              <Col className="text-center"></Col>
              <Col className="text-right">{selectedOrder.location.name}</Col>
            </Row>
          </CardHeader>
          <div
            className="px-4 pt-4 pb-3"
            style={{ borderBottom: "1px solid #ccc" }}
          >
            <Row>
              <Col>
                <h3>Order #{selectedOrder.id}</h3>
              </Col>
              <Col className="text-center">
                <h3>{selectedOrder.customer.first_name}{" "}{selectedOrder.customer.last_name}</h3>
              </Col>
              <Col className="text-right">
                <h3>{selectedOrder.customer.phone}</h3>
              </Col>
            </Row>
          </div>
          <CardBody>
            <div className="od-order">
              <div className="od-header">
                <div className="od-h-item">
                  <span className="od-h-i-t">ORDER TYPE</span>
                  <span className="od-h-i-d">{selectedOrder.type}</span>
                </div>
                <div className="od-h-item">
                  <span className="od-h-i-t">SUBMITTED TIME</span>
                  <span className="od-h-i-d">{selectedOrder.created_at}</span>
                </div>
                <div className={this.isDelayedRequest() ? "od-h-item request" : "od-h-item"}>
                  <span className="od-h-i-t">REQUEST TIME</span>
                  <span className="od-h-i-d">
                    {this.isDelayedRequest() ? selectedOrder.request_at : "ASAP"}
                  </span>
                </div>
              </div>
              <div className="od-header pt-3">
                <div className="od-h-item confirmed">
                  <span className="od-h-i-t">CONFIRMED @</span>
                  <span className="od-h-i-d">{selectedOrder.confirmed_at ?? "N/A"}</span>
                </div>
                <div className="od-h-item ready">
                  <span className="od-h-i-t">READY @</span>
                  <span className="od-h-i-d">{selectedOrder.ready_at ?? "N/A"}</span>
                </div>
                <div className="od-h-item done">
                  <span className="od-h-i-t">COMPLETE @</span>
                  <span className="od-h-i-d">{selectedOrder.done_at ?? "N/A"}</span>
                </div>
              </div>


              {selectedOrder.status === "canceled" && !selectedOrder.confirmed_at ? (
                <Alert color="danger" className="mt-5">
                  <h5>
                    Order canceled,{" "}
                    {selectedOrder.confirmed_at
                      ? `$${formatCurrency(
                        selectedOrder.price_summary.refunds_sum
                      )} has been refunded.`
                      : "no money is collected."}
                  </h5>
                  <p>Reason: {selectedOrder.reason_to_cancel}</p>
                </Alert>
              ) : null}
              {selectedOrder.status !== "canceled" &&
                selectedOrder.price_summary.refunds.length > 0 ? (
                  <Alert color="danger" className="mt-5">
                    <h6>Refunds Issued</h6>
                    <ul className="nav flex-column">
                      {selectedOrder.price_summary.refunds.map((refund, key) => (
                        <li key={`refudn-${key}`}>
                          {`$${formatCurrency(refund.amount)}`} - {refund.note}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                ) : null}
              <ul className="nav od-items">
                {selectedOrder.order_items.map((item, i) => (
                  <li className="od-item" key={`oditem-${item.id}`}>
                    <div className="odi-header">
                      <span
                        className={classnames([
                          "odi-qty",
                          { warning: item.quantity > 1 },
                        ])}
                      >
                        {item.quantity}x
                    </span>
                    </div>
                    <div className="odi-body">
                      <div className="name">{item.name}</div>
                      <div className="desc">{item.category_name ?? null}</div>
                      <div className="name">{item.options ?? "N/A"}</div>
                      {item.customer_note ? (
                        <div className="customer-note">
                          Customer Note: {item.customer_note}
                        </div>
                      ) : null}
                    </div>
                    <div className="odi-footer">
                      <div className="price">
                        {item.total.original !== item.total.updated ? (
                          <span className="opp-original">${item.total.original}</span>
                        ) : null}
                        <span className="opp-updated">${item.total.updated}</span>
                      </div>
                    </div>
                  </li>
                ))}
                {
                  selectedOrder.location_coupon ?
                    selectedOrder.location_coupon.coupon ?
                      selectedOrder.location_coupon.coupon.type === "free_item" ?
                        <li className="od-item" key={`oditem-coupon`}>
                          <div className="odi-header">
                            <span
                              className={classnames([
                                "odi-qty",
                                { warning: false },
                              ])}
                            >
                              1x
                  </span>
                          </div>
                          <div className="odi-body">
                            <div className="name">{selectedOrder.location_coupon.coupon.description}</div>
                          </div>
                          <div className="odi-footer">
                            <div className="price">
                              <span className="opp-updated">$0.00</span>
                            </div>
                          </div>
                        </li> : null : null : null
                }

              </ul>
              <div className="od-summary">
                <div className="ods-header">
                  <div>
                    <div className="odsh-qty">{selectedOrder.order_items_count} items in total</div>
                    {this.renderCurbsideInfo()}
                  </div>

                </div>
                <div className="ods-footer">
                  <table>
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td>${selectedOrder.price_summary.subtotal}</td>
                      </tr>
                      {
                        selectedOrder.price_summary.coupon && (selectedOrder.price_summary.coupon > 0) ?
                          <tr>
                            <td>Coupon</td>
                            <td>-${selectedOrder.price_summary.coupon}</td>
                          </tr> : null
                      }
                      <tr>
                        <td>Tax</td>
                        <td>${selectedOrder.price_summary.sales_tax}</td>
                      </tr>
                      {selectedOrder.type === "delivery" ? (
                        <tr>
                          <td>Delivery</td>
                          <td>${selectedOrder.price_summary.delivery_fee}</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>Tip</td>
                        <td>${selectedOrder.price_summary.tip}</td>
                      </tr>
                      {selectedOrder.price_summary.service_fee ? (
                        <tr>
                          <td>Service Fee</td>
                          <td>${formatCurrency(selectedOrder.price_summary.service_fee)}</td>
                        </tr>
                      ) : null}
                      {selectedOrder.price_summary.refunds_sum ? (
                        <tr>
                          <td>Refund</td>
                          <td>${formatCurrency(selectedOrder.price_summary.refunds_sum)}</td>
                        </tr>
                      ) : null}
                      {
                        selectedOrder.price_summary.service_fee ?
                          <tr>
                            <td>Convenience Fee</td>
                            <td>${selectedOrder.price_summary.service_fee}</td>
                          </tr> : null
                      }
                      <tr>
                        <td>TOTAL</td>
                        <td>${selectedOrder.total}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>

          </CardBody>
        </Card>
      );
    }
  }

}

export default OrderCard;
