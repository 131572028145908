import {
  ORDER_INITIATE_REQUEST,
  ORDER_INITIATE_SUCCESS,
  ORDER_INITIATE_FAIL,
  ORDER_GET_ORDERS_REQUEST,
  ORDER_GET_ORDERS_SUCCESS,
  ORDER_GET_ORDERS_FAIL,
  ORDER_GET_ORDER_REQUEST,
  ORDER_GET_ORDER_SUCCESS,
  ORDER_GET_ORDER_FAIL,
  ORDER_CHANGE_STATUS_REQUEST,
  ORDER_CHANGE_STATUS_SUCCESS,
  ORDER_CHANGE_STATUS_FAIL,
  ORDER_GET_STATS_REQUEST,
  ORDER_GET_STATS_SUCCESS,
  ORDER_GET_STATS_FAIL,
  ORDER_CLEAR_ORDER_REQUEST
} from "../actions/order";

const initialState = { ordersPagination: null };

const order = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_INITIATE_REQUEST:
      return {
        ...state,
        initiateOrderRequest: true
      };
    case ORDER_INITIATE_SUCCESS:
      return {
        ...state,
        initiateOrderRequest: false,
        ordersPagination: action.data
      };
    case ORDER_INITIATE_FAIL:
      return {
        ...state,
        initiateOrderRequest: false
      };
    case ORDER_GET_ORDERS_REQUEST:
      return {
        ...state,
        getOrdersRequest: true
      };
    case ORDER_GET_ORDERS_SUCCESS:
      return {
        ...state,
        getOrdersRequest: false,
        ordersPagination: action.data,
        ordersReview: action.review
      };
    case ORDER_GET_ORDERS_FAIL:
      return {
        ...state,
        getOrdersRequest: false,
        ordersPagination: null,
      };
    case ORDER_CLEAR_ORDER_REQUEST:
      return {
        ...state,
        getOrderRequest: false,
        selectedOrder: null
      };
    case ORDER_GET_ORDER_REQUEST:
      return {
        ...state,
        getOrderRequest: true
      };
    case ORDER_GET_ORDER_SUCCESS:
      return {
        ...state,
        getOrderRequest: false,
        selectedOrder: action.data
      };
    case ORDER_GET_ORDER_FAIL:
      return {
        ...state,
        getOrderRequest: false
      };
    case ORDER_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        changeStatusRequest: true,
        changeStatusErrors: null
      };
    case ORDER_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        changeStatusRequest: false,
        changeStatusErrors: null
      };
    case ORDER_CHANGE_STATUS_FAIL:
      return {
        ...state,
        changeStatusRequest: false,
        changeStatusErrors: action.errors
      };
    case ORDER_GET_STATS_REQUEST:
      return {
        ...state,
        getStatusRequest: true
      };
    case ORDER_GET_STATS_SUCCESS:
      return {
        ...state,
        getStatsRequest: false,
        orderStats: action.data
      };
    case ORDER_GET_STATS_FAIL:
      return {
        ...state,
        getStatusRequest: false
      };
    default:
      return state;
  }
};

export default order;
