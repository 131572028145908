import React from "react";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
// import { Link } from "react-router-dom";

function LoginCard(props) {
  let { email, password } = props.data;
  return (
      <Card className="auth-card">
        <CardBody>
          <Form onSubmit={props.login}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                name="Email"
                onChange={e => props.onInputChange('email', e.target.value)}
                value={email || ''}

              />
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input
                type="password"
                name="Password"
                onChange={e => props.onInputChange('password', e.target.value)}
                value={password || ''}

              />
            </FormGroup>
            <FormGroup>
              <Button
                color="primary"
                type="submit"
                className="btn-shadow"
                disabled={props.requesting || !email || !password}
                block
              >Login</Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
  );
}

export default LoginCard;
