import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const DISPUTE_GET_DISPUTES_REQUEST = "DISPUTE_GET_DISPUTES_REQUEST";
export const DISPUTE_GET_DISPUTES_SUCCESS = "DISPUTE_GET_DISPUTES_SUCCESS";
export const DISPUTE_GET_DISPUTES_FAIL = "DISPUTE_GET_DISPUTES_FAIL";
export const getDisputes = ({
  location_id,
  statuses,
  brand_id,
  refresh = false,
}) => (dispatch) => {
  dispatch({ type: DISPUTE_GET_DISPUTES_REQUEST });
  axios
    .request({
      url: "ma/disputes",
      params: {
        location_id,
        statuses,
        brand_id,
      },
      method: "get",
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.redirect) return;

      if (data.success) {
        dispatch({
          type: DISPUTE_GET_DISPUTES_SUCCESS,
          data: data.data,
        });
      } else {
        dispatch({ type: DISPUTE_GET_DISPUTES_FAIL });
        toast.warn(data.message);
      }
    });
};

export const DISPUTE_GET_DISPUTE_REQUEST = "DISPUTE_GET_DISPUTE_REQUEST";
export const DISPUTE_GET_DISPUTE_SUCCESS = "DISPUTE_GET_DISPUTE_SUCCESS";
export const DISPUTE_GET_DISPUTE_FAIL = "DISPUTE_GET_DISPUTE_FAIL";
export const getDispute = ({ dispute_id, refresh = false }) => (dispatch) => {
  dispatch({ type: DISPUTE_GET_DISPUTE_REQUEST });
  axios
    .request({
      url: "ma/dispute",
      params: {
        dispute_id,
      },
      method: "get",
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.redirect) return;

      if (data.success) {
        dispatch({
          type: DISPUTE_GET_DISPUTE_SUCCESS,
          data: data.data,
        });
      } else {
        dispatch({ type: DISPUTE_GET_DISPUTE_FAIL });
        toast.warn(data.message);
      }
    });
};

export const DISPUTE_CLOSE_DISPUTE_REQUEST = "DISPUTE_CLOSE_DISPUTE_REQUEST";
export const DISPUTE_CLOSE_DISPUTE_SUCCESS = "DISPUTE_CLOSE_DISPUTE_SUCCESS";
export const DISPUTE_CLOSE_DISPUTE_FAIL = "DISPUTE_CLOSE_DISPUTE_FAIL";
export const closeDispute = ({ dispute_id, refresh = false }) => (dispatch) => {
  dispatch({ type: DISPUTE_CLOSE_DISPUTE_REQUEST });
  axios
    .request({
      url: "ma/close_dispute",
      params: {
        dispute_id,
      },
      method: "post",
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.redirect) return;

      if (data.success) {
        dispatch({
          type: DISPUTE_CLOSE_DISPUTE_SUCCESS,
        });
        dispatch(getDispute({ dispute_id, refresh: true }));
        toast.success(data.message);
      } else {
        dispatch({ type: DISPUTE_CLOSE_DISPUTE_FAIL });
        toast.warn(data.message);
      }
    });
};

export const DISPUTE_UPLOAD_EVIDENCE_REQUEST =
  "DISPUTE_UPLOAD_EVIDENCE_REQUEST";
export const DISPUTE_UPLOAD_EVIDENCE_SUCCESS =
  "DISPUTE_UPLOAD_EVIDENCE_SUCCESS";
export const DISPUTE_UPLOAD_EVIDENCE_FAIL = "DISPUTE_UPLOAD_EVIDENCE_FAIL";
export const uploadDisputeEvidence = ({
  dispute_id,
  customer_communication_file,
  customer_signature_file,
}) => (dispatch) => {
  dispatch({ type: DISPUTE_UPLOAD_EVIDENCE_REQUEST });
  let formData = new FormData();
  formData.append("dispute_id", dispute_id);
  formData.append("customer_communication_file", customer_communication_file);
  formData.append("customer_signature_file", customer_signature_file);
  axios
    .request({
      url: "ma/upload_dispute_evidence",
      data: formData,
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      if (data.redirect) return;

      if (data.success) {
        dispatch({
          type: DISPUTE_UPLOAD_EVIDENCE_SUCCESS,
        });
        dispatch(getDispute({ dispute_id, refresh: true }));
        toast.success(data.message);
      } else {
        dispatch({
          type: DISPUTE_UPLOAD_EVIDENCE_FAIL,
          msg: data.message,
          errors: data.errors,
        });
        toast.warn(data.message);
      }
    });
};
