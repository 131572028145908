import React from "react";
import { Button } from "reactstrap";

class SingleDocUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
  }
  componentDidUpdate(prevProps) {}
  onFileChange(e) {
    if (e.target.files.length === 0) {
      return;
    }
    this.setState({
      file: e.target.files[0],
    });
    this.props.onChange(e.target.files[0]);
  }

  onRemoveFile() {
    this.setState({
      file: null,
    });
    this.props.onChange(null);
  }
  render() {
    if (this.props.readOnly) {
      return (
        <div className="upload-doc">
          <div className="upload-doc-wrap">
            <div className="preview">
              {this.props.defaultFile ? this.props.defaultFile : "None"}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="upload-doc">
        {this.state.file ? (
          <div className="upload-doc-wrap">
            <div className="preview">{this.state.file.name}</div>
            <div className="remove">
              <Button
                color="link"
                className="btn-popover px-0"
                onClick={this.onRemoveFile}
              >
                <i className="far fa-times"></i>
              </Button>
            </div>
          </div>
        ) : (
          <div className="upload-zone">
            <input type="file" onChange={this.onFileChange} />
            <Button color="link" className="f-10 px-0">
              UPLOAD
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default SingleDocUploader;
