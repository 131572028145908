import React from "react";
import { Card, Table, Row, Col, CardHeader, } from "reactstrap";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ReactSelect from 'react-select';
import { DebounceInput } from 'react-debounce-input';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import { rgb } from "chroma-js";
import { DialogTitle } from "@material-ui/core";

function MainCard(props) {

  const renderLocations = () => {
    let { locations } = props;
    if (!locations) return null;
    if (!locations.data) return null;
    if (locations.data.length === 0) return null;
    return locations.data.map((location, key) => {
      // let olod = {
      //   enabled: location.status === 'active',
      //   verified: false,
      //   payout: false,
      // };

      return (
        <tr
          key={key}
          onClick={(e) => {
            props.onChange('selectedLocation4Users', { location_id: location.id, brand_id: location.brand_id }, true);
            setOpen(true)
          }
          }
        >
          <td>{location.brand.name}</td>
          <td>{location.name}</td>
          <td>{location.id}</td>
          <td>{location.address ? location.address.state : null}</td>
          <td>{location.address ? location.address.zipcode : null}</td>
          <td className={location.status === 'active' ? 'text-green' : 'text-danger'}>{location.status.toUpperCase()}</td>
          {/* <td className="olod-dots">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-circle-fill" fill={olod.enabled ? rgb(3,201,169):rgb(136,152,170)} xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="6" />
            </svg>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-circle-fill" fill={olod.verified ?rgb(3,201,169):rgb(136,152,170)} xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="6" />
            </svg>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-circle-fill" fill={olod.payout ?rgb(3,201,169) :rgb(136,152,170)} xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="6" />
            </svg>
          </td> */}
          <td style={{ fontSize: 20, textAlign: "center", lineHeight: 1 }}>
            <i className="fal fa-angle-right"></i>
          </td>
        </tr>
      );
    });
  };

  const renderStatuses = () => {
    let all_statuses = [
      { value: "", label: "All Status" },
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
      { value: "pending", label: "Pending" },
    ];
    return (
      <div ><ReactSelect
        value={props.formData.selectedStatus}
        name="selected_status"
        options={all_statuses}
        classNamePrefix="STATUS"
        onChange={(value) => props.onChange("selectedStatus", value, true)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#03c9a9",
            primary25: "#eaeaea",
          },
        })}
      /></div>

    );
  };

  const brands = () => {

    let { allBrands } = props;
    if (!allBrands) return [];
    let brands = allBrands.map(item => ({
      value: item.id,
      label: item.name
    })
    );
    let placeholder = [{ value: '', label: 'All Brands' }];
    brands = [...placeholder, ...brands];
    return brands;
  };

  const renderUsers = () => {
    if (props.getUsersRequest) return (
      <tr>
        <td colSpan="3">
          <i className="fad fa-spinner fa-spin mr-2"></i> Loading
        </td>
      </tr>
    );
    if (!props.users) return null;
    return props.users.data.map((item, i) => {
      let location_names = [];
      if (item.role === 'brand_owner') location_names = 'All';
      else {
        item.employments.forEach(item => { location_names.push(item.location.name); });
        location_names = location_names.join(', ');
      }

      let role_name = 'N/A';
      switch (item.role) {
        case 'brand_admin':
          role_name = 'Brand Admin';
          break;
        case 'location_owner':
          role_name = 'Location Owner';
          break;
        case 'manager':
          role_name = 'Manager';
          break;
        case 'staff':
          role_name = 'Staff';
          break;
        case 'channel_partner':
          role_name = 'Channel Partner';
          break;
        case 'coordinator':
          role_name = 'Coordinator';
          break;

        default:
          break;
      }

      return (
        <tr
          key={i}
          onClick={(e) =>
            props.onChange('user_id', item.id, true)}
        >
          <th scope="row">{item.first_name + " " + item.last_name}</th>
          <td>{role_name}</td>
          <td className="text-right">
            <i className="far fa-chevron-right"></i>
          </td>
        </tr >
      );
    });
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (

    <Card className="main-card">
      <CardHeader>
        <Row>
          <Col>
            <ReactSelect
              placeholder="BRANDS"
              value={props.formData.selectedBrand}
              name="all_brands"
              options={brands()}
              isLoading={props.getBrandsRequest}
              classNamePrefix="BRANDS"
              onChange={(value) => props.onChange("selectedBrand", value, true)}
              onInputChange={(value) => props.onChange("brand_name", value, true)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#03c9a9",
                  primary25: "#eaeaea",
                },
              })}
            />
          </Col>
          <Col>
            <DebounceInput
              className="fillter-input"
              placeholder="LOCATION #"
              value={props.formData.location_id}
              minLength={1}
              debounceTimeout={500}
              onChange={(e) => props.onChange("location_id", e.target.value, true)}
            />

          </Col>
          <Col>
            <DebounceInput
              className="fillter-input"
              placeholder="STATE"
              value={props.formData.state}
              minLength={1}
              debounceTimeout={500}
              onChange={(e) => props.onChange("state", e.target.value, true)}
            />
          </Col>
          <Col>
            <DebounceInput
              className="fillter-input"
              placeholder="ZIPCODE"
              value={props.formData.zipcode}
              minLength={1}
              debounceTimeout={500}
              onChange={(e) => props.onChange("zipcode", e.target.value, true)}
            />
          </Col>
          <Col>
            {renderStatuses()}
          </Col>
        </Row>
      </CardHeader>

      <Table responsive hover>
        <thead>
          <tr>
            <th>BRAND</th>
            <th>LOCATION</th>
            <th>ID</th>
            <th>STATE</th>
            <th>ZIPCODE</th>
            <th>STATUS</th>
            {/* <th>OLOD</th> */}
            <th>ENTER</th>
          </tr>
        </thead>

        <tbody>
          {renderLocations()}
        </tbody>
      </Table>
      {props.renderPagination()}

      {<Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>LOGIN AS</DialogTitle>
        <DialogContent>
          <Table hover>
            <tbody>
              <tr>
                <th>USER</th>
                <th>ROLE</th>
                <th></th>
              </tr>
            </tbody>

            <tbody>
              {renderUsers()}
            </tbody>

          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}  >
            Close
          </Button>
        </DialogActions>
      </Dialog>}
    </Card>
  );
}


export default MainCard;
