import React from "react";
import OrderCard from "../../components/orders/OrderCard";

import {
  getOrder,
} from "../../store/actions/order";
import { connect } from "react-redux";

class Order extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        statuses: null,
      },
      selectedOrder: null,
      cancelOrderModalType: "",
      openConfirmOrderModal: false,
    };

    this.goBack = this.goBack.bind(this);
    this.getOrder = this.getOrder.bind(this);
    this.toggleCancelOrderModal = this.toggleCancelOrderModal.bind(this);
    this.handleOrderChangeStatus = this.handleOrderChangeStatus.bind(this);
  }


  componentDidMount() {
    this.getOrder();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.order.selectedOrder !== this.props.order.selectedOrder) {
      this.setState({
        openConfirmOrderModal: false,
        openCancelOrderModal: false,
        selectedOrder: this.props.order.selectedOrder,
      });
    }
  }

  goBack() {
    this.props.history.push({

      pathname: "/orders",
      state: this.props.location.state
    });
  }

  getOrder() {
    let { oid } = this.props.match.params;
    this.props.getOrder({ order_id: oid });
  }

  toggleCancelOrderModal(modalType) {
    this.setState({
      cancelOrderModalType: modalType,
      openCancelOrderModal: !this.state.openCancelOrderModal,
    });

  }


  handleOrderChangeStatus({
    status,
    prep_time,
    reason_to_cancel,
    refund,
    access_code,
  }) {
    let { selectedOrder } = this.props;
    if (!selectedOrder) return;

    let order_id = selectedOrder.id;
    this.props.changeOrderStatus({
      order_id,
      status,
      prep_time,
      reason_to_cancel,
      refund,
      access_code,
    });
  }

  render() {
    return <div>
      <OrderCard
        goBack={this.goBack}
        selectedOrder={this.state.selectedOrder}
        onCancelOrder={this.toggleCancelOrderModal}
        onConfirmOrder={this.toggleConfirmOrderModal}
      />
    </div>

      ;
  }
}

const mapStateToProps = (state) => {
  return {

    order: state.order,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrder: (params) => dispatch(getOrder(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Order);