import {
  LCT_GET_ALL_LOCATIONS_REQUEST,
  LCT_GET_ALL_LOCATIONS_SUCCESS,
  LCT_GET_ALL_LOCATIONS_FAIL,
  LCT_GET_LOCATIONS_REQUEST,
  LCT_GET_LOCATIONS_SUCCESS,
  LCT_GET_LOCATIONS_FAIL,
} from "../actions/location";

const initialState = {
  locations: [],
  selectedLocation: null,
  locationPlans: [],
  getCouponRequest: false,
};

const location = (state = initialState, action) => {
  switch (action.type) {
    case LCT_GET_ALL_LOCATIONS_REQUEST:
      return {
        ...state,
        getAllLocationsRequest: true,
      };
    case LCT_GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        getAllLocationsRequest: false,
        allLocations: action.data,
      };
    case LCT_GET_ALL_LOCATIONS_FAIL:
      return {
        ...state,
        getAllLocationsRequest: false,
      };
    case LCT_GET_LOCATIONS_REQUEST:
      return {
        ...state,
        getLocationsRequest: true,
      };
    case LCT_GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        getLocationsRequest: false,
        locations: action.data,
        selectedLocation: null,
      };
    case LCT_GET_LOCATIONS_FAIL:
      return {
        ...state,
        getLocationsRequest: false,
        locations: null,
      };
    default:
      return state;
  }
};

export default location;
