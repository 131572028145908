import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import order from "./order";
import location from "./location";
import dispute from "./dispute";
import user from "./user";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    order,
    location,
    dispute,
    user,
  });
