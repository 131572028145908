import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { TOKEN_KEY } from "../../utils/constant";
import { getUser } from "../actions/user";

export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";
export const login = ({ email = null, password = null, from }) => dispatch => {
  dispatch({ type: AUTH_LOGIN_REQUEST });
  axios
    .request({
      url: "auth/login",
      data: { email, password, role_type: 'brand' },
      method: "post"
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: AUTH_LOGIN_SUCCESS,
          msg: data.message
        });

        Cookies.set(TOKEN_KEY, data.data.access_token, {
          expires: new Date(data.data.expires_at)
        });

        // make sure user is master
        dispatch(getUser({ refresh: true }));

        toast.info(data.message);
        dispatch(push({ pathname: "/orders" }));
      } else {
        dispatch({
          type: AUTH_LOGIN_FAIL,
          msg: data.message,
          errors: data.errors ? data.errors : {}
        });
        toast.warn(data.message);
      }
    });
};

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const logout = () => dispatch => {
  dispatch({ type: AUTH_LOGOUT });

  let access_token = Cookies.get(TOKEN_KEY);
  if (!access_token) return dispatch(push("/login"));

  axios
    .request({
      url: "logout",
      method: "post"
    })
    .then(({ data }) => {
      if (data.success) {
        toast.success("You have logged out.");
      }
      Cookies.remove(TOKEN_KEY);
      dispatch(push("/login"));
    });
};

export const AUTH_GET_BRANDS_REQUEST = "AUTH_GET_BRANDS_REQUEST";
export const AUTH_GET_BRANDS_SUCCESS = "AUTH_GET_BRANDS_SUCCESS";
export const AUTH_GET_BRANDS_FAIL = "AUTH_GET_BRANDS_FAIL";
export const getBrands = (
  {
    brand_name,
  }
) => dispatch => {
  if (!brand_name) return;
  dispatch({ type: AUTH_GET_BRANDS_REQUEST });
  axios
    .request({
      url: "/ma/brands",
      params: {
        name: brand_name,
      },
      method: "get",
      cache: { ignoreCache: true },
    })
    .then(({ data }) => {
      if (data.redirect) return;

      if (data.success) {
        dispatch({
          type: AUTH_GET_BRANDS_SUCCESS,
          data: data.data,
          review: data.message,
        });

      } else {
        dispatch({ type: AUTH_GET_BRANDS_FAIL });
        toast.warn(data.message);
      }
    });
};

