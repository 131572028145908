import axios from "axios";
import { toast } from "react-toastify";
// import { push } from "connected-react-router";
export const LCT_GET_LOCATIONS_REQUEST = "LCT_GET_LOCATIONS_REQUEST";
export const LCT_GET_LOCATIONS_SUCCESS = "LCT_GET_LOCATIONS_SUCCESS";
export const LCT_GET_LOCATIONS_FAIL = "LCT_GET_LOCATIONS_FAIL";
export const LCT_GET_ALL_LOCATIONS_REQUEST = "LCT_GET_ALL_LOCATIONS_REQUEST";
export const LCT_GET_ALL_LOCATIONS_SUCCESS = "LCT_GET_ALL_LOCATIONS_SUCCESS";
export const LCT_GET_ALL_LOCATIONS_FAIL = "LCT_GET_ALL_LOCATIONS_FAIL";
export const getLocations = ({
  brand_id,
  brand_name,
  location_id,
  location_name,
  get_all = 0,
  per_page = 15,
  page = 1,
  status,
  zipcode,
  state,
}) => (
  dispatch,
  getState
) => {
    dispatch({ type: LCT_GET_LOCATIONS_REQUEST });

    axios
      .request({
        url: "ma/locations",
        params: {
          brand_id,
          location_id,
          location_name,
          get_all,
          per_page,
          page,
          status,
          zipcode,
          state,
          brand_name,
        },
        method: "get",
        cache: { ignoreCache: true },
      })
      .then(({ data }) => {
        if (data.redirect) return;
        dispatch(
          { type: LCT_GET_LOCATIONS_REQUEST }
        );

        if (data.success) {
          dispatch(
            { type: LCT_GET_LOCATIONS_SUCCESS, data: data.data }
          );
        } else {
          dispatch(
            { type: LCT_GET_LOCATIONS_FAIL }
          );
          toast.warn(data.message);
        }
      });
  };

export const LCT_CLEAR_LOCATIONS_REQUEST = "LCT_CLEAR_LOCATIONS_REQUEST";
export const clearLocations = () => (dispatch) => {
  dispatch({ type: LCT_CLEAR_LOCATIONS_REQUEST });
};





