import React from "react";
import Cookies from "js-cookie";
import { Route, Redirect } from "react-router-dom";
import { TOKEN_KEY } from "../../utils/constant";
import AuthLayout from "./AuthLayout";


class AuthLayoutRoute extends React.Component {
  render() {
    // location: the href
    const { component: Component, location, ...rest } = this.props;
    let hasToken = Cookies.get(TOKEN_KEY);
    let { from } = { from: { pathname: "/" } }; //this.props.location.state || 
    // this.props.history.replace(from);
    return (
      <Route
        {...rest}
        render={(props) =>
          !hasToken ? (
            <AuthLayout {...props}>
              <Component {...props} />
            </AuthLayout>
          ) : (
            <Redirect to={from} />
          )
        }
      />
    );
  }
}

export default AuthLayoutRoute;
