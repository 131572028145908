import React from "react";
import MainCard from "../../components/restaurants/MainCard";
import { connect } from "react-redux";
import NinjaPagination from "../../components/common/NinjaPagination";
import { getLocations } from "../../store/actions/location";
import { getBrands } from "../../store/actions/auth";
import { getUsers } from "../../store/actions/user";
import { postLoginAs } from "../../store/actions/user";
import { debounce } from "../../utils/tools";
import { RUSHABLE_ADMIN_HOST } from "../../utils/constant";

class Restaurants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        selectedBrand: { value: "", label: "BRANDS" },
        brand_name: null,
        location_id: null,
        get_all: 0,
        per_page: 15,
        page: 1,
        selectedStatus: { value: "", label: "All" },
        zipcode: null,
        state: null,
        olod_status: { enabled: "", verified: "", bank_account: "" },
        user_id: null,
        selectedLocation4Users: { location_id: null, brand_id: null },
      },
      modalOpen: false,
      websiteOpen: false,
    };

    this.onFormChange = this.onFormChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.getBrands = this.getBrands.bind(this);
    this.postLoginAs = this.postLoginAs.bind(this);
    this.debounceSubmit = debounce(this.debounceSubmit.bind(this), 500);
    this.toggleModal = this.toggleModal.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }
  componentDidMount() {
    let prevFormData = this.props.location.state
      ? this.props.location.state.prevFormData
      : {};
    let formData = {
      ...this.state.formData,
      ...prevFormData,
    };
    this.setState({
      formData,
    });
    this.getBrands(formData);
    this.goToPage();
    this.toggleModal();
  }

  getLocations(formData) {
    if (!formData) return null;

    let brand_id = formData.selectedBrand.value;
    let location_id = formData.location_id;
    let get_all = formData.get_all;
    let per_page = formData.per_page;
    let status = formData.selectedStatus.value;
    let zipcode = formData.zipcode;
    let state = formData.state;
    let page = formData.page;

    this.props.getLocations({
      brand_id,
      location_id,
      get_all,
      per_page,
      page,
      status,
      zipcode,
      state,
    });
  }

  getBrands(formData) {
    if (!formData) return null;
    let brand_name = formData.brand_name;
    this.props.getBrands({
      brand_name,
    });
  }

  postLoginAs = async (formData) => {
    if (!formData) return null;
    let user_id = formData.user_id;
    let success = await this.props.postLoginAs({
      user_id,
    });
    if (success) {
      this.goToRushable(formData.selectedLocation4Users);
    }
  };

  getUsers(formData) {
    if (!formData) return null;
    let location_id = formData.selectedLocation4Users.location_id;
    this.props.getUsers({
      location_id,
    });
  }

  onFormChange(name, value, submit = false) {
    if (name === "page") {
      this.setState({
        formData: {
          ...this.state.formData,
          page: value,
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value,
          page: submit ? 1 : this.state.formData.page,
        },
      });
    }

    if (name === "brand_name") {
      setTimeout(() => {
        if (submit) this.debounceSubmit();
      }, 300);
    } else {
      setTimeout(() => {
        if (submit) this.onFormSubmit(name);
      }, 300);
    }
  }

  debounceSubmit() {
    this.getBrands(this.state.formData);
  }

  onFormSubmit(name) {
    switch (name) {
      case "user_id":
        this.postLoginAs(this.state.formData);
        break;
      case "selectedLocation4Users":
        this.getUsers(this.state.formData);
        setTimeout(() => {
          this.toggleModal(true);
        }, 300);
        break;
      default:
        this.getLocations(this.state.formData);
        break;
    }
  }

  goToPage(page) {
    let formData = {
      ...this.state.formData,
      page,
    };
    this.setState({ formData });
    this.getLocations(formData);
  }

  goToRushable(selectedLocation4Users) {
    window.open(
      `${RUSHABLE_ADMIN_HOST}brand/${selectedLocation4Users.brand_id}/location/${selectedLocation4Users.location_id}`,
      "_blank"
    );
  }

  toggleModal(type) {
    this.setState({
      ...this.state.modalOpen,
      modalOpen: type,
    });
  }

  renderPagination = () => {
    let locations = this.props.locations;
    if (!locations) return null;
    if (!locations.data) return null;
    if (locations.data.length === 0) return null;

    return (
      <NinjaPagination
        currentPage={locations.current_page}
        totalPages={locations.last_page}
        pageNeighbours={1}
        goToPage={(page) => this.goToPage(page)}
      />
    );
  };

  render() {
    return (
      <MainCard
        renderPagination={this.renderPagination}
        formData={this.state.formData}
        getLocationsRequest={this.props.getLocationsRequest}
        locations={this.props.locations}
        debounceInput={this.debounceInput}
        onChange={this.onFormChange}
        allBrands={this.props.allBrands}
        postLoginAs={this.props.postLoginAs}
        goToRushable={this.goToRushable}
        toggleModal={this.toggleModal}
        modalOpen={this.state.modalOpen}
        getUsersSuccess={this.props.getUsersSuccess}
        getUsersRequest={this.props.getUsersRequest}
        users={this.props.user.users}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.location.locations,
    getLocationsRequest: state.location.getLocationsRequest,
    allBrands: state.auth.allBrands,
    postLoginAsRequest: state.user.postLoginAsRequest,
    getUsersSuccess: state.user.getUsersSuccess,
    getUsersRequest: state.user.getUsersRequest,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: (params) => dispatch(getLocations(params)),
    getBrands: (params) => dispatch(getBrands(params)),
    postLoginAs: (params) => dispatch(postLoginAs(params)),
    getUsers: (params) => dispatch(getUsers(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);
