import React from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import LOGO from "../../assets/img/logo-black.svg";

class Sidenav extends React.Component {
  render() {
    let activeLink = this.props.location.pathname;
    return (
      <Navbar
        className="navbar-vertical fixed-left"
        fixed="left"
        color="white"
        light
        expand="md"
      >
        <NavbarBrand href="/">
          <img src={LOGO} alt="Rushable" />
        </NavbarBrand>
        <NavbarToggler />
        <Collapse isOpen={true}>
          <Nav navbar>
            <NavItem>
              <NavLink
                active={activeLink.includes("/order")}
                tag={Link}
                to="/orders"
              >
                <i
                  className="fal fa-receipt"
                  style={{ fontSize: 16, paddingLeft: 2 }}
                ></i>{" "}
                Order Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeLink.includes("/restaurants")}
                tag={Link}
                to="/restaurants"
              >
                <i className="fal fa-store"></i> Restaurant List
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeLink.includes("/disputes")}
                tag={Link}
                to="/disputes"
              >
                <i className="fal fa-credit-card-blank"></i> Disputes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.logout();
                }}
              >
                <i
                  className="fal fa-sign-out-alt"
                  style={{ fontSize: 17, paddingLeft: 2 }}
                ></i>{" "}
                Log Out
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default Sidenav;
