
export const filterFromObjectArray = (options, val, valueKey) => {
  let selected = options.filter((item) => {
    // eslint-disable-next-line eqeqeq
    return item[valueKey] == val;
  });
  if (!selected.length) return null;
  return selected[0];
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
