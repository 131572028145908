/* eslint-disable eqeqeq */
/*
 * format phone to (xxx) xxx-xxxx when keyup
 */
export const onPhoneKeyUp = (e, phoneInput) => {
  var ph = phoneInput;
  ph = ph.replace(/\D/g, "").substring(0, 10);
  // Backspace and Delete keys
  var deleteKey = e.keyCode == 8 || e.keyCode == 46;
  var len = ph.length;
  if (len == 0) {
  } else if (len < 3) {
    ph = "(" + ph;
  } else if (len == 3) {
    ph = "(" + ph + (deleteKey ? "" : ") ");
  } else if (len < 6) {
    ph = "(" + ph.substring(0, 3) + ") " + ph.substring(3, 6);
  } else if (len == 6) {
    ph =
      "(" +
      ph.substring(0, 3) +
      ") " +
      ph.substring(3, 6) +
      (deleteKey ? "" : "-");
  } else {
    ph =
      "(" +
      ph.substring(0, 3) +
      ") " +
      ph.substring(3, 6) +
      "-" +
      ph.substring(6, 10);
  }
  return ph;
};

/*
 * allow 0-9 and delete key
 */
export const onPhoneKeyDown = (e) => {
  -1 !== [46, 8, 9, 27, 13, 110, 189].indexOf(e.keyCode) ||
    (/65|67|86|88/.test(e.keyCode) &&
      (e.ctrlKey === true || e.metaKey === true) &&
      (!0 === e.ctrlKey || !0 === e.metaKey)) ||
    (35 <= e.keyCode && 40 >= e.keyCode) ||
    ((e.shiftKey || 48 > e.keyCode || 57 < e.keyCode) &&
      (96 > e.keyCode || 105 < e.keyCode) &&
      e.preventDefault());
};

/*
 * remove all char to digit only 0-9
 */
export const formatDigitOnly = (inputText) => {
  return inputText.toString().replace(/\D/g, "");
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

/*
 * money input, allow: 0-9 . delete
 */
export const onNumericInputKeydown = (e) => {
  -1 !== [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) ||
    (/65|67|86|88/.test(e.keyCode) &&
      (e.ctrlKey === true || e.metaKey === true) &&
      (!0 === e.ctrlKey || !0 === e.metaKey)) ||
    (35 <= e.keyCode && 40 >= e.keyCode) ||
    ((e.shiftKey || 48 > e.keyCode || 57 < e.keyCode) &&
      (96 > e.keyCode || 105 < e.keyCode) &&
      e.preventDefault());
};

export const formatCurrency = (moneyString, decimal = 2) => {
  if (isNaN(moneyString)) {
    return 0;
  }
  return parseFloat(Math.round(parseFloat(moneyString) * 100) / 100).toFixed(
    decimal
  );
};

export const convertToCurrency = (input) => {
  let value = input;
  // remove all characters that aren't digit or dot
  value = value.replace(/[^0-9.]/g, "");
  // replace multiple dots with a single dot
  value = value.replace(/\.+/g, ".");
  // only allow 2 digits after a dot
  value = value.replace(/(.*\.[0-9][0-9]?).*/g, "$1");
  // replace multiple zeros with a single one
  value = value.replace(/^0+(.*)$/, "0$1");
  // remove leading zero
  value = value.replace(/^0([^.].*)$/, "$1");
  return value;
};
