import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import LOGO from "../../assets/img/logo-black.svg";
import Close from "../../components/common/Close";
// import moment from "moment";


class AuthLayout extends React.Component {
  componentDidMount() {
    this.unlisten = this.props.history.listen((location) => {
     
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidUpdate(prevProps) {
    
  }


  render() {
    let { children } = this.props;
    return (
      <div className="auth-layout">
      <ToastContainer
          autoClose={2 * 1000}
          position={toast.POSITION.TOP_CENTER}
          closeButton={<Close />}
        />
        <img className="logo" src={LOGO} alt="Rushable" />
        <div className="full-page">{children}</div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);