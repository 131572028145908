import React from "react";
import Sidenav from "../../components/common/Sidebar";
import { TOKEN_KEY } from "../../utils/constant";
import Cookies from "js-cookie";
import { logout } from "../../store/actions/auth";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Close from "../../components/common/Close";

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }
  logout() {
    Cookies.remove(TOKEN_KEY);
    window.location.href = "/login";
  }
  render() {
    let { children, ...rest } = this.props;
    return (
      <div className="dashboard-layout">
        <ToastContainer
          autoClose={2 * 1000}
          position={toast.POSITION.TOP_CENTER}
          closeButton={<Close />}
        />
        <Sidenav {...rest} logout={this.logout} />
        <div className="main-content">
          <div className="container-fluid">{children}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    pathname: state.router.location.pathname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
