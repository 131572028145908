import React from "react";
import MainCard from "../../components/disputes/MainCard";
import { connect } from "react-redux";
import NinjaPagination from "../../components/common/NinjaPagination";
import { getLocations } from "../../store/actions/location";
import { getBrands } from "../../store/actions/auth";
import { getDisputes } from "../../store/actions/dispute"
import { debounce } from '../../utils/tools'

class Disputes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        statuses: { value: '', label: "All" },
        keyword: null,
        selectedLocation: { value: '', label: 'LOCATION' },
        selectedLocationId: { value: '', label: 'LOCATION ID' },
        location_id: '',
        location_name:'',
        selectedBrand: { value: '', label: 'BRAND' },
        brand_id: '',
        brand_name: '',
      },

    };
    this.onFormChange = this.onFormChange.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.getBrands = this.getBrands.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.getDisputes = this.getDisputes.bind(this);
    this.debounceSubmit = debounce(this.debounceSubmit.bind(this), 500)
    this.seeDisputeDetail = this.seeDisputeDetail.bind(this);
  }
  componentDidMount() {
    let prevFormData = this.props.location.state
      ? this.props.location.state.prevFormData
      : {};
    let formData = {
      ...this.state.formData,
      ...prevFormData,
    };
    this.setState({
      formData,
    });
    this.getLocations(formData);
    this.getBrands(formData);
    this.getDisputes(formData);
  }

  getLocations(formData) {
    if (!formData) return null;
    let location_name = formData.location_name;
    this.props.getLocations({
      location_name,
    });
  }

  getBrands(formData) {
    if (!formData) return null;
    let brand_name = formData.brand_name;
    this.props.getBrands({
      brand_name,
    });
  }

  getDisputes(formData) {
    if (!formData) return null;
    let statuses = formData.statuses.value;
    let location_id = formData.selectedLocation.value;
    let brand_id = formData.selectedBrand.value;
    this.props.getDisputes({
      statuses,
      location_id,
      brand_id,
    });
  }

  onFormChange(name, value, submit = false) {
    if (name === 'page') {
      this.setState({
        formData: {
          ...this.state.formData,
          page: value,
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value,
          page: submit ? 1 : this.state.formData.page,
        },
      });
    }

    setTimeout(() => {
      if (submit) this.debounceSubmit(name);
    }, 200);
  }

  debounceSubmit(name) {
    switch (name) {
      case 'brand_name':
        this.getBrands(this.state.formData);
        break;
      case 'location_name':
        this.getLocations(this.state.formData);
        break;
      default:
        this.getDisputes(this.state.formData);
        break;
    }
  }

  goToPage(page) {
    let formData = {
      ...this.state.formData,
      page,
    };
    this.setState({ formData });
  }

  seeDisputeDetail(disputeId) {
    this.props.history.push(
      {
        pathname: "/dispute/" + disputeId,
        state: { prevFormData: this.state.formData }
      }
    );
  }

  renderPagination = () => {
    let disputes = this.props.disputes;
    if (!disputes) return null;
    if (!disputes.data) return null;
    if (disputes.data.length === 0) return null;

    return (
      <NinjaPagination
        currentPage={disputes.current_page}
        totalPages={disputes.last_page}
        pageNeighbours={1}
        goToPage={(page) => this.goToPage(page)}
      />
    );
  };

  render() {
    return <MainCard
      renderPagination={this.renderPagination}
      formData={this.state.formData}
      onChange={this.onFormChange}
      seeDisputeDetail={this.seeDisputeDetail}
      getDisputesRequest={this.props.getDisputesRequest}
      debounceInput={this.debounceInput}
      allBrands={this.props.allBrands}
      disputes={this.props.disputes}
      locations={this.props.locations}
    />;
  }
}


const mapStateToProps = (state) => {
  return {
    getDisputesRequest: state.dispute.getDisputesRequest,
    disputes: state.dispute.disputesPagination,
    allBrands: state.auth.allBrands,
    locations: state.location.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: (params) => dispatch(getLocations(params)),
    getBrands: (params) => dispatch(getBrands(params)),
    getDisputes: (params) => dispatch(getDisputes(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Disputes);