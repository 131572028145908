
import React from "react";
import { Row, Col, Button, Card, CardHeader, CardBody } from "reactstrap";
import moment from "moment";
import { formatCurrency } from "../../utils/digit";
import SingleDocUploader from "../disputes/SingleDocUploader";


class DisputeCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasEvidence: false,
            needUploadFile: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dispute !== this.props.dispute) {
            if (this.props.dispute) {
                let { info } = this.props.dispute;
                if (!info) return;
                let { customer_communication, customer_signature } = info.evidence;
                this.setState({
                    needUploadFile: !customer_communication && !customer_signature,
                    hasEvidence: customer_communication || customer_signature,
                });
            }
        }
    }

    render() {
        if (this.props.getDisputeRequest === false) {
            return (
                <Card className="main-card">
                    <CardBody>
                        <div className="od-order">
                            <h3 >
                                Failed to get information
                            </h3>
                        </div>
                    </CardBody>
                </Card>
            )
        }

        else if (this.props.dispute == null) {
            return (
                <Card className="main-card">
                    <CardBody>
                        <div className="od-order">
                            <h3 className="text-success">
                                {/* <i className="fal fa-spinner fa-spin mr-2"></i>Loading... */}
                            </h3>
                        </div>
                    </CardBody>
                </Card>
            )
        } else {
            const renderReason = (reason) => {
                let reasonDetail = "N/A";
                switch (reason) {
                    case "bank_cannot_process":
                        reasonDetail = "Bank cannot process.";
                        break;
                    case "check_returned":
                        reasonDetail = "Check returned.";
                        break;
                    case "credit_not_processed":
                        reasonDetail =
                            "The customer claims that the purchased product was returned or the transaction was otherwise canceled, but you have not yet provided a refund or credit.";
                        break;
                    case "customer_initiated":
                        reasonDetail = "Customer_initiated";
                        break;
                    case "debit_not_authorized":
                        reasonDetail = "Debit not authorized.";
                        break;
                    case "duplicate":
                        reasonDetail =
                            "The customer claims they were charged multiple times for the same product or service.";
                        break;
                    case "fraudulent":
                        reasonDetail =
                            "This is the most common reason for a dispute and happens when a cardholder claims that they didn’t authorize the payment. This can happen if the card was lost or stolen and used to make a fraudulent purchase. It can also happen if the cardholder doesn’t recognize the payment as it appears on the billing statement from their card issuer.";
                        break;
                    case "general":
                        reasonDetail =
                            "This is an uncategorized dispute, so you should contact the customer for additional details to find out why the payment was disputed.";
                        break;
                    case "incorrect_account_details":
                        reasonDetail = "Incorrect account details.";
                        break;
                    case "insufficient_funds":
                        reasonDetail = "Insufficient funds.";
                        break;
                    case "product_not_received":
                        reasonDetail =
                            "The customer claims they did not receive the products or services purchased.";
                        break;
                    case "product_unacceptable":
                        reasonDetail =
                            "The product or service was received but was defective, damaged, or not as described.";
                        break;
                    case "subscription_canceled":
                        reasonDetail =
                            "The customer claims that you continued to charge them after a subscription was canceled.";
                        break;
                    case "unrecognized":
                        reasonDetail =
                            "The customer doesn’t recognize the payment appearing on their card statement.";
                        break;
                    default:
                        break;
                }
                return reasonDetail;
            };
            const renderStatus = (status) => {
                let statusStr = "N/A";
                let statusColor = "status-gray";
                switch (status) {
                    case "warning_needs_response":
                    case "needs_response":
                        statusStr = "Needs Response";
                        statusColor = "status-orange";
                        break;
                    case "warning_under_review":
                    case "under_review":
                    case "rushable_under_review":
                        statusStr = "Under Review";
                        statusColor = "status-blue";
                        break;
                    case "charge_refunded":
                        statusStr = "Acepted";
                        statusColor = "status-gray";
                        break;
                    case "warning_closed":
                        statusStr = "Closed";
                        statusColor = "status-gray";
                        break;
                    case "won":
                        statusStr = "Won";
                        statusColor = "status-green";
                        break;
                    case "lost":
                        statusStr = "Lost";
                        statusColor = "status-red";
                        break;
                    default:
                        break;
                }
                return (
                    <span>
                        <span className={statusColor}>● </span>
                        {statusStr}
                    </span>
                );
            };

            const renderResponseDaysLeft = (status, response_by) => {
                if (status === "warning_needs_response" || status === "needs_response") {
                    let days = moment(response_by)
                        .startOf("day")
                        .subtract(2, "days")
                        .diff(moment(Date.now()).startOf("day"), "days");
                    return (
                        <div >
                            You have {days} days left to respond to this dispute
                        </div>
                    );
                }
                return null;
            };

            const renderResult = (status) => {
                let statusStr = "";
                let statusColor = "alert-gray-2";
                switch (status) {
                    case "charge_refunded":
                        statusStr = "You have refunded the charge of this dispute.";
                        statusColor = "alert-gray-2";
                        break;
                    case "warning_closed":
                        statusStr =
                            "You have accepted this dispute or it has timed out and did not escalate into a full dispute";
                        statusColor = "alert-gray-2";
                        break;
                    case "won":
                        statusStr =
                            "Successfully appealed, the fund has been back to your account.";
                        statusColor = "alert-green";
                        break;
                    case "lost":
                        statusStr =
                            "Unfortunately, the customer’s bank has denied your appeal.";
                        statusColor = "alert-red";
                        break;
                    default:
                        break;
                }
                return statusStr ? (
                    <div className={"alert text-center mt-5 " + statusColor}>
                        {statusStr}
                    </div>
                ) : null;
            };

            const renderEvidence = (status) => {
                if (
                    ["charge_refunded", "warning_closed", "won", "lost"].indexOf(status) >
                    -1
                ) {
                    return null;
                }
                if (!this.state.hasEvidence) {
                    return (
                        <div className="d-flex justify-content-end mb-3">
                            <Button
                                color="outline-secondary"
                                className="mx-2"
                                onClick={() => this.setState({ hasEvidence: true })}
                            >
                                Appeal with evidences
            </Button>
                            <Button color="outline-secondary">Accept Dispute</Button>
                        </div>
                    );
                }

                let { info } = this.props.dispute;
                info = info ? info : { evidence: {} };
                let { customer_communication, customer_signature } = info.evidence;
                var evidenceView = (
                    <div className="mt-5">
                        <Row className="mb-3">
                            <Col md={6}>
                                <h5 className="f-12">
                                    Customer signature
                                </h5>
                                <SingleDocUploader
                                    defaultFile={
                                        customer_signature ? customer_signature.filename : ""
                                    }
                                    readOnly={true}
                                />
                            </Col>
                            <Col md={6}>

                                <h5 className="f-12">
                                    Customer communication
                                </h5>
                                <SingleDocUploader
                                    defaultFile={
                                        customer_communication ? customer_communication.filename : ""
                                    }
                                    readOnly={true}
                                />
                            </Col>
                        </Row>
                    </div>
                );
                return evidenceView;
            };

            if (!this.props.dispute) return null;
            let { info, amount, reason, response_by, status } = this.props.dispute;
            if (!info) return null;
            let { payment_method_details, billing_details } = info.charge;
            let { brand, last4, country } = payment_method_details.card;
            let { line1, line2, city, state, postal_code } = billing_details.address;

            return (
                <Card className="main-card">
                    <CardHeader className="dark">
                        <Row>
                            <Col>
                                <Button
                                    color="link d-flex align-items-center p-0"
                                    onClick={this.props.goBack}>
                                    <i className="fal fa-angle-left fa-2x mr-2"></i>BACK
                                </Button>
                            </Col>
                            <Col className="text-center">{renderResponseDaysLeft(status, response_by)}</Col>
                            <Col className="text-right"></Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="px-5 mw-768 mx-auto">
                            <div className="nonres">
                                {this.props.getDisputeRequest ? (
                                    <h5 className="text-success">
                                        <i className="fal fa-spinner fa-spin mr-2"></i> Loading
                                    </h5>
                                ) : null}

                                <div className="label-content">
                                    <div className="label">REASON</div>
                                    <div className="lcontent p">{renderReason(reason)}</div>
                                </div>
                                <Row>
                                    <Col>
                                        <div className="label-content">
                                            <div className="label">STATUS</div>
                                            <div className="lcontent p">{renderStatus(status)}</div>
                                        </div>
                                        <div className="label-content">
                                            <div className="label">AMOUNT</div>
                                            <div className="lcontent p">${formatCurrency(amount)}</div>
                                        </div>
                                        <div className="label-content">
                                            <div className="label">OWNER</div>
                                            <div className="lcontent p">
                                                {billing_details.name ? billing_details.name : "None"}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="label-content">
                                            <div className="label">CARD</div>
                                            <div className="lcontent p">
                                                {brand} {last4}
                                            </div>
                                        </div>
                                        <div className="label-content">
                                            <div className="label">ORIGIN</div>
                                            <div className="lcontent p">{country}</div>
                                        </div>
                                        <div className="label-content">
                                            <div className="label">ADDRESS</div>
                                            <div className="lcontent p">
                                                {line1 ? (
                                                    <div>
                                                        {line1}
                                                        <br />
                                                        {line2}
                                                        <br />
                                                        {city}, {state} {postal_code}
                                                    </div>
                                                ) : (
                                                        "None"
                                                    )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                {renderEvidence(status)}
                                {renderResult(status)}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        }

    }
}

export default DisputeCard;
