import React from "react";
import { Card, Table, Row, Col, CardHeader, } from "reactstrap";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ReactSelect from 'react-select';
import moment from "moment";
import { formatCurrency } from "../../utils/digit";
import { rgb } from "chroma-js";
import Rectangle from 'react-rectangle';

function MainCard(props) {


  // const renderOlodstatus = () => {
  //   let olodStatus = props.formData.olodStatus;


  // }

  const renderStatus = (status) => {
    let statusStr = "N/A";
    let statusColor = "status-gray";
    switch (status) {
      case "warning_needs_response":
      case "needs_response":
        statusStr = "Needs Response";
        statusColor = "status-orange";
        break;
      case "warning_under_review":
      case "under_review":
      case "rushable_under_review":
        statusStr = "Under Review";
        statusColor = "status-blue";
        break;
      case "charge_refunded":
        statusStr = "Charge Refunded";
        statusColor = "status-red";
        break;
      case "warning_closed":
        statusStr = "Closed";
        statusColor = "status-gray";
        break;
      case "won":
        statusStr = "Won";
        statusColor = "status-green";
        break;
      case "lost":
        statusStr = "Lost";
        statusColor = "status-red";
        break;
      default:
        break;
    }
    return <span className={statusColor}>{statusStr}</span>;
  };

  const renderTable = () => {
    if (!props.disputes) return null;
    if (props.disputes.length === 0) return null;
    let disputes = props.disputes.data;
    return disputes.map((dispute, key) => {
      let squre = {
        customerSignature: dispute.has_customer_signature === 1,
        customerCommunication: dispute.has_customer_communication === 1,
      };
      let squreStyle = { background: squre.customerCommunication ? rgb(3, 201, 169) : rgb(136, 152, 170), width: '13px', height: '13px', float: "left" };
      return (
        <tr
          key={key}
          onClick={(e) => props.seeDisputeDetail(dispute.id)}
        >
          <td>{renderStatus(dispute.status)}</td>
          <td>${formatCurrency(dispute.amount)}</td>
          <td>{moment(dispute.disputed_at).format("MM/DD - hh:mm a")}</td>
          <td>{moment(dispute.response_by).format("MM/DD - hh:mm a")}</td>
          <td>
            <div style={{ width: '13px', height: '13px', float: "left", marginLeft: "15px" }}>
              <Rectangle aspectRatio={[1, 1]}>
                <div style={squreStyle} />
              </Rectangle>
            </div>
            <div style={{ width: '13px', height: '13px', float: "left", marginLeft: "15px", }}>
              <Rectangle aspectRatio={[1, 1]}>
                <div style={squreStyle} />
              </Rectangle>
            </div>
          </td>
          <td>{dispute.brand_name}</td>
          <td>{dispute.location_name}</td>
          <td>{dispute.location_id}</td>

        </tr>
      );
    });
  };

  const brands = () => {
    let { allBrands } = props;
    if (!allBrands) return [{ value: '', label: 'Loading' }];
    if (allBrands.length === 0) return [{ value: '', label: 'Loading' }];
    let placeholder = [{ value: '', label: 'All Brands'}];
    let brands = allBrands.map(item => ({
      value: item.id,
      label: item.name
    }));
    brands = [...placeholder, ...brands];
    return brands;
  };

  const locationOption = () => {
    let { locations } = props;
    if (!locations) return [{ value: '', label: 'Loading' }];
    if (!locations.data) return [{ value: '', label: 'Loading' }];
    if (locations.data.length === 0) return [{ value: '', label: 'Loading' }];
    let locationOption = 
    locations.data.map(item => ({
      value: item.id,
      label: item.name
    })
    );
    let placeholder = [{ value: '', label: 'All Locations'}];
    locationOption = [...placeholder, ...locationOption];
    return locationOption;
  };

  // const locationIdOption = () => {
  //   let { locations } = props;
  //   if (!locations) return [{ value: '', label: 'Loading' }];
  //   if (!locations.data) return [{ value: '', label: 'Loading' }];
  //   if (locations.data.length === 0) return [{ value: '', label: 'Loading' }];
  //   let locationIdOption = locations.data.map(item => ({
  //     value: item.id,
  //     label: item.id
  //   })
  //   );
  //   return locationIdOption;
  // };

  const renderStatuses = () => {
    let all_statuses = [
      { value: "", label: "All" },
      { value: ["warning_needs_response", "needs_response"], label: "Needs Response" },
      { value: ["warning_under_review", "under_review"], label: "Under Review" },
      { value: ["charge_refunded"], label: "Charge Refunded" },
      { value: ["warning_closed"], label: "Closed" },
      { value: ["won"], label: "Won" },
      { value: ["lost"], label: "Lost" },
    ];
    return (
      <div ><ReactSelect
        value={props.formData.statuses}
        placeholder="STATUS"
        name="selected_status"
        options={all_statuses}
        classNamePrefix="STATUS"
        onChange={(value) => props.onChange("statuses", value, true)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#03c9a9",
            primary25: "#eaeaea",
          },
        })}
      /></div>

    );
  };

  return (
    <Card className="main-card">
      <CardHeader>
        <Row>
          <Col>
            {renderStatuses()}
          </Col>
          <Col>
            <ReactSelect
              placeholder="BRAND"
              value={props.formData.selectedBrand}
              name="all_brands"
              options={brands()}
              classNamePrefix="BRANDS"
              onChange={(value) => props.onChange("selectedBrand", value, true)}
              onInputChange={(value) => props.onChange("brand_name", value, true)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#03c9a9",
                  primary25: "#eaeaea",
                },
              })}
            />
          </Col>
          <Col>
            <ReactSelect
              placeholder="LOCATION"
              value={props.formData.selectedLocation}
              name="locations"
              options={locationOption()}
              classNamePrefix="BRANDS"
              onChange={(value) => props.onChange("selectedLocation", value, true)}
              onInputChange={(value) => props.onChange("location_name", value, true)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#03c9a9",
                  primary25: "#eaeaea",
                },
              })}
            />
          </Col>
          {/* <Col>
            <ReactSelect
              placeholder="LOCATION ID"
              value={props.formData.location_id}
              name="location id"
              options={locationIdOption()}
              classNamePrefix="BRANDS"
              onChange={(value) => props.onChange("selectedLocationId", value, true)}
              onInputChange={(value) => props.onChange("location_id", value, true)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#03c9a9",
                  primary25: "#eaeaea",
                },
              })}
            /> 
          </Col> */}
          <Col>
          </Col>
        </Row>
      </CardHeader>

      <Table responsive hover>
        <thead>
          <tr>
            <th>STATUS</th>
            <th>AMOUNT</th>
            <th>DATE</th>
            <th>RESPONSE BY</th>
            <th>UPLOADS</th>
            <th>BRAND</th>
            <th>LOCATION</th>
            <th>LOCATION ID</th>
          </tr>
        </thead>
        <tbody>
          {renderTable()}

        </tbody>

      </Table>
      {props.renderPagination()}
    </Card>
  );
}


export default MainCard;



