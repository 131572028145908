import axios from "axios";
import { toast } from "react-toastify";
import { logout } from "../actions/auth";

export const USER_GET_USERS_REQUEST = "USER_GET_USERS_REQUEST";
export const USER_GET_USERS_SUCCESS = "USER_GET_USERS_SUCCESS";
export const USER_GET_USERS_FAIL = "USER_GET_USERS_FAIL";
export const getUsers = ({
  role_type,
  keyword,
  brand_id,
  location_id,
  page,
  refresh = false,
}) => (dispatch) => {
  dispatch({ type: USER_GET_USERS_REQUEST });
  let location_ids = null;
  if (location_id) location_ids = [location_id];
  axios
    .request({
      url: "ma/users",
      params: { role_type, keyword, brand_id, location_ids, page },
      method: "get",
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({
          type: USER_GET_USERS_SUCCESS,
          msg: data.message,
          data: data.data,
        });
      } else {
        dispatch({
          type: USER_GET_USERS_FAIL,
          msg: data.message,
          errors: data.errors,
        });
      }
    });
};

export const USER_GET_USER_REQUEST = "USER_GET_USER_REQUEST";
export const USER_GET_USER_SUCCESS = "USER_GET_USER_SUCCESS";
export const USER_GET_USER_FAIL = "USER_GET_USER_FAIL";
export const getUser = ({ refresh = false }) => (dispatch) => {
  dispatch({ type: USER_GET_USER_REQUEST });
  axios
    .request({
      url: "ma/user",
      method: "get",
      cache: { ignoreCache: refresh },
    })
    .then(({ data }) => {
      if (data.success) {
        if (data.data.role !== 'master') {
          dispatch(logout());
          return toast.warn('Access denied');
        }

        dispatch({
          type: USER_GET_USER_SUCCESS,
          data: data.data,
        });
      } else {
        dispatch({ type: USER_GET_USER_FAIL });
        toast.warn(data.message);
      }
    });
};

export const POST_LOGINAS_REQUEST = "POST_LOGINAS_REQUEST";
export const POST_LOGINAS_SUCCESS = "POST_LOGINAS_SUCCESS";
export const POST_LOGINAS_FAIL = "POST_LOGINAS_FAIL";
export const postLoginAs = ({
  user_id
}) => async (dispatch) => {
  dispatch({ type: POST_LOGINAS_REQUEST });
  return axios
    .request({
      url: "/ma/user/facade",
      data: {
        user_id: user_id
      },
      method: "post",
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: POST_LOGINAS_SUCCESS, data: data.data });
        return true;
      } else {
        dispatch({ type: POST_LOGINAS_FAIL, errors: data.errors });
        toast.warn(data.message);
        return false;
      }
    });
};