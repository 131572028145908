import React from "react";
import { connect } from "react-redux";
import DisputeCard from "../../components/disputes/DisputeCard"
import {
  getDispute,
  uploadDisputeEvidence,
  closeDispute,
} from "../../store/actions/dispute";
import { toast } from "react-toastify";

import { confirmAlert } from "react-confirm-alert";

class Dispute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                customer_communication_file: null,
                customer_signature_file: null,
            },
        };
        this.getDipute = this.getDipute.bind(this);
        this.uploadEvidence = this.uploadEvidence.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.closeDispute = this.closeDispute.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    componentDidMount() {
        this.getDipute();
    }

    getDipute() {
        let { disputeId } = this.props.match.params;
        this.props.getDispute({ dispute_id: disputeId });
    }

    uploadEvidence() {
        let { disputeId } = this.props.match.params;
        let {
            customer_communication_file,
            customer_signature_file,
        } = this.state.formData;
        if (!customer_communication_file || !customer_signature_file) {
            toast.warn("Please upload all required files");
            return;
        }
        this.props.uploadDisputeEvidence({
            dispute_id: disputeId,
            customer_communication_file,
            customer_signature_file,
        });
    }

    onFormChange(name, val) {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: val,
      },
    });
  }

  closeDispute() {
    let { disputeId } = this.props.match.params;
    confirmAlert({
      title: "ACCEPT DISPUTE",
      message:
        "Are you sure to accept this dispute? Accepting the dispute for a charge indicates that you do not have any evidence to submit and are essentially dismissing the dispute, acknowledging it as lost.",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => {
            this.props.closeDispute({ dispute_id: disputeId });
          },
        },
        { label: "No" },
      ],
    });
  }

  goBack() {
    this.props.history.push({
      
      pathname:"/disputes",
      state:this.props.location.state
    });
  }

  render() {
    return (
      <>
            <DisputeCard
              {...this.props}
              goBack={this.goBack} 
              uploadEvidence={this.uploadEvidence}
              onFormChange={this.onFormChange}
              closeDispute={this.closeDispute}
              getDisputeRequest={this.state.getDisputeRequest}
            />
         
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        dispute: state.dispute.dispute,
        getDisputeRequest: state.dispute.getDisputeRequest,
        uploadEvidenceRequest: state.dispute.uploadEvidenceRequest,
        uploadEvidenceMsg: state.dispute.uploadEvidenceMsg,
        uploadEvidenceErrors: state.dispute.uploadEvidenceErrors,
        closeDisputeRequest: state.dispute.closeDisputeRequest,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDispute: (params) => dispatch(getDispute(params)),
        uploadDisputeEvidence: (params) => dispatch(uploadDisputeEvidence(params)),
        closeDispute: (params) => dispatch(closeDispute(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dispute);
