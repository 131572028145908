import React from "react";
import MainCard from "../../components/orders/MainCard";
import { connect } from "react-redux";
import NinjaPagination from "../../components/common/NinjaPagination";

import { getOrders } from "../../store/actions/order";
import moment from "moment";
import { getBrands } from "../../store/actions/auth";
import { debounce } from '../../utils/tools'



class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        statuses: null,
        type: null,
        keyword: null,
        dateRange: [new Date(), new Date()],
        page: null,
        review_mode: null,
        per_page: 15,
        location_id: null,
        selectedBrand: { value: '', label: 'BRANDS' },
        zipcode: null,
        brand_name: '',
      },
      modalOpen: false,

    };
    this.seeOrderDetail = this.seeOrderDetail.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.getBrands = this.getBrands.bind(this);
    this.debounceSubmit = debounce(this.debounceSubmit.bind(this), 500)
  }
  componentDidMount() {
    let prevFormData = this.props.location.state
      ? this.props.location.state.prevFormData
      : {};
    let formData = {
      ...this.state.formData,
      ...prevFormData,
    };
    this.setState({
      formData,
    });
    this.getOrders(formData);
    this.getBrands(formData);
  }

  getOrders(formData) {
    if (!formData) return null;
    let statuses = formData.statuses;
    let type = formData.type;
    let keyword = formData.keyword;
    let begin_time = formData.dateRange[0];
    let end_time = formData.dateRange[1];
    let page = formData.page;
    let review_mode = formData.review_mode;
    let per_page = formData.per_page;
    let location_id = formData.location_id;
    let brand_id = formData.selectedBrand.value;
    let zipcode = formData.zipcode;
    begin_time = moment(begin_time).startOf("day").format("YYYY-MM-DD HH:mm:ss");
    end_time = moment(end_time).endOf("day").format("YYYY-MM-DD HH:mm:ss");

    this.props.getOrders({
      statuses,
      type,
      keyword,
      end_time,
      page,
      review_mode,
      per_page,
      begin_time,
      location_id,
      brand_id,
      zipcode,

    });
  }

  getBrands(formData) {
    if (!formData) return null;
    let brand_name = formData.brand_name;
    this.props.getBrands({
      brand_name,
    });
  }

  onFormChange(name, value, submit = false) {
    if (name === 'page') {
      this.setState({
        formData: {
          ...this.state.formData,
          page: value,
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value,
          page: submit ? 1 : this.state.formData.page,
        },
      });
    }

    if (name === 'brand_name') {
      setTimeout(() => {
        if (submit) this.debounceSubmit();
      }, 300);
    } else {
      setTimeout(() => {
        if (submit) this.onFormSubmit();
      }, 300);
    }

  }

  debounceSubmit() {
    this.getBrands(this.state.formData);
  }

  onFormSubmit() {
    this.getOrders(this.state.formData);
  }

  toggleModal(status = true) {
    this.setState({
      modalOpen: status,
    });

    if (!status) this.onFormSubmit();
  }

  goToPage(page) {
    let formData = {
      ...this.state.formData,
      page,
    };
    this.setState({ formData });
    this.getOrders(formData);
  }

  seeOrderDetail(oid) {
    this.props.history.push(
      {
        pathname: "/order/" + oid,
        state: { prevFormData: this.state.formData }
      }


    );
  }
  renderPagination = () => {
    let orders = this.props.orders;
    if (!orders) return null;
    if (!orders.data) return null;
    if (orders.data.length === 0) return null;

    return (
      <NinjaPagination
        currentPage={orders.current_page}
        totalPages={orders.last_page}
        pageNeighbours={1}
        goToPage={(page) => this.goToPage(page)}
      />
    );
  };


  render() {
    return <MainCard
      seeOrderDetail={this.seeOrderDetail}
      renderPagination={this.renderPagination}
      formData={this.state.formData}
      toggleModal={this.toggleModal}
      modalOpen={this.state.modalOpen}
      onSubmit={this.onFormSubmit}
      onChange={this.onFormChange}
      getOrdersRequest={this.props.getOrdersRequest}
      orders={this.props.orders}
      debounceInput={this.debounceInput}
      allBrands={this.props.allBrands}
      getBrandsRequest={this.props.getBrandsRequest}
    />;
  }
}


const mapStateToProps = (state) => {
  return {
    orders: state.order.ordersPagination,
    ordersReview: state.order.ordersReview,
    getOrdersRequest: state.order.getOrdersRequest,
    allBrands: state.auth.allBrands,
    getBrandsRequest: state.auth.getBrandsRequest
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: (params) => dispatch(getOrders(params)),
    getBrands: (params) => dispatch(getBrands(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);