import {
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAIL,
    AUTH_GET_BRANDS_REQUEST,
    AUTH_GET_BRANDS_SUCCESS,
    AUTH_GET_BRANDS_FAIL,
  } from "../actions/auth";
  
  const initialState = {
    loginRequest: false,
    loginError: "",
    profile: null,
    logoutRequest: false,
    resetRequest: null
  };
  
  const auth = (state = initialState, action) => {
    switch (action.type) {
      case AUTH_LOGIN_REQUEST:
        return {
          ...state,
          loginRequest: true,
          loginError: "",
          profile: null
        };
      case AUTH_LOGIN_SUCCESS:
        return {
          ...state,
          loginRequest: false
        };
      case AUTH_LOGIN_FAIL:
        return {
          ...state,
          loginRequest: false,
          loginError: action.msg
        };
        case AUTH_GET_BRANDS_REQUEST:
          return {
            ...state,
            getBrandsRequest: true
          };
        case AUTH_GET_BRANDS_SUCCESS:
          return {
            ...state,
            getBrandsRequest: false,
            allBrands: action.data,
          };
        case AUTH_GET_BRANDS_FAIL:
          return {
            ...state,
            getBrandsRequest: false
          };
   
      default:
        return state;
    }
  };
  
  export default auth;