import {
  DISPUTE_GET_DISPUTES_REQUEST,
  DISPUTE_GET_DISPUTES_SUCCESS,
  DISPUTE_GET_DISPUTES_FAIL,
  DISPUTE_GET_DISPUTE_REQUEST,
  DISPUTE_GET_DISPUTE_SUCCESS,
  DISPUTE_GET_DISPUTE_FAIL,
  DISPUTE_UPLOAD_EVIDENCE_REQUEST,
  DISPUTE_UPLOAD_EVIDENCE_SUCCESS,
  DISPUTE_UPLOAD_EVIDENCE_FAIL,
  DISPUTE_CLOSE_DISPUTE_REQUEST,
  DISPUTE_CLOSE_DISPUTE_SUCCESS,
  DISPUTE_CLOSE_DISPUTE_FAIL,
} from "../actions/dispute";

const initialState = {
  disputesPagination: null,
};

const dispute = (state = initialState, action) => {
  switch (action.type) {
    case DISPUTE_GET_DISPUTES_REQUEST:
      return {
        ...state,
        getDisputesRequest: true,
      };
    case DISPUTE_GET_DISPUTES_SUCCESS:
      return {
        ...state,
        getDisputesRequest: false,
        disputesPagination: action.data,
      };
    case DISPUTE_GET_DISPUTES_FAIL:
      return { ...state, getDisputesRequest: false };
    case DISPUTE_GET_DISPUTE_REQUEST:
      return {
        ...state,
        getDisputeRequest: true,
      };
    case DISPUTE_GET_DISPUTE_SUCCESS:
      return {
        ...state,
        getDisputeRequest: false,
        dispute: action.data,
      };
    case DISPUTE_GET_DISPUTE_FAIL:
      return {
        ...state,
        getDisputeRequest: false,
        dispute: null,
      };
    case DISPUTE_UPLOAD_EVIDENCE_REQUEST:
      return {
        ...state,
        uploadEvidenceRequest: true,
        uploadEvidenceMsg: "",
        uploadEvidenceErrors: null,
      };
    case DISPUTE_UPLOAD_EVIDENCE_SUCCESS:
      return {
        ...state,
        uploadEvidenceRequest: false,
        uploadEvidenceMsg: "",
        uploadEvidenceErrors: null,
      };
    case DISPUTE_UPLOAD_EVIDENCE_FAIL:
      return {
        ...state,
        uploadEvidenceRequest: false,
        uploadEvidenceMsg: action.msg,
        uploadEvidenceErrors: action.errors,
      };
    case DISPUTE_CLOSE_DISPUTE_REQUEST:
      return {
        ...state,
        closeDisputeRequest: true,
      };
    case DISPUTE_CLOSE_DISPUTE_SUCCESS:
      return {
        ...state,
        closeDisputeRequest: false,
      };
    case DISPUTE_CLOSE_DISPUTE_FAIL:
      return {
        ...state,
        closeDisputeRequest: false,
      };
    default:
      return state;
  }
};

export default dispute;
