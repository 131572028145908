import React from "react";
import { Switch, Redirect } from "react-router-dom"; //Router,
import DashboardLayoutRoute from "./pages/layouts/DashboardLayoutRoute";
import Orders from "./pages/orders/Orders";
import Restaurants from "./pages/restaurants/Restaurants";
import Disputes from "./pages/disputes/Disputes";
import Order from "./pages/orders/Order";
import Login from "./pages/auth/Login";
import AuthLayoutRoute from "./pages/layouts/AuthLayoutRoute";
import { Provider } from "react-redux";

import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store/store";

import httpService from "./utils/api.interceptors";
import Dispute from "./pages/disputes/Dispute";



httpService.setupInterceptors(store, history);

function App() {
  return (
    <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <DashboardLayoutRoute path="/orders" component={Orders} />
        <DashboardLayoutRoute path="/restaurants" component={Restaurants} />
        <DashboardLayoutRoute path="/disputes" component={Disputes} />
        <DashboardLayoutRoute path="/order/:oid" component={Order} />
        <DashboardLayoutRoute path="/dispute/:disputeId" component={Dispute} />
        
        <AuthLayoutRoute path="/login" component={Login} />
        <Redirect exact from="/" to="/orders" />
        
      </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
