import axios from "axios";
import { toast } from "react-toastify";


export const ORDER_INITIATE_REQUEST = "ORDER_INITIATE_REQUEST";
export const ORDER_INITIATE_SUCCESS = "ORDER_INITIATE_SUCCESS";
export const ORDER_INITIATE_FAIL = "ORDER_INITIATE_FAIL";
export const initiateOrder = ({
  sales_tax,
  prior_to_close,
  purchase_tablet,
  email_notification,
  agree_to_pay,
  delivery_type,
  delivery_range,
  delivery_fee,
  order_min,
  third_party_links,
}) => (dispatch) => {
  dispatch({ type: ORDER_INITIATE_REQUEST });
  return axios
    .request({
      url: "ma/order/online/initiate",
      data: {

        sales_tax,
        prior_to_close,
        purchase_tablet,
        email_notification,
        agree_to_pay,
        delivery_type,
        delivery_range,
        delivery_fee,
        order_min,
        third_party_links,
      },
      method: "post",
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: ORDER_INITIATE_SUCCESS, data: data.data });
        return true;
      } else {
        dispatch({ type: ORDER_INITIATE_FAIL, errors: data.errors });
        toast.warn(data.message);
        return false;
      }
    });
};

export const ORDER_GET_ORDERS_REQUEST = "ORDER_GET_ORDERS_REQUEST";
export const ORDER_GET_ORDERS_SUCCESS = "ORDER_GET_ORDERS_SUCCESS";
export const ORDER_GET_ORDERS_FAIL = "ORDER_GET_ORDERS_FAIL";
export const getOrders = ({
  location_id,
  statuses,
  type,
  keyword,
  end_time,
  page,
  review_mode,
  per_page,
  begin_time,
  brand_id,
  zipcode,
}) => (dispatch) => {
  dispatch({ type: ORDER_GET_ORDERS_REQUEST });
  axios
    .request({
      url: "ma/orders/online",
      params: {
        location_id,
        statuses,
        type,
        keyword,
        end_time,
        page,
        review_mode,
        per_page,
        begin_time,
        brand_id,
        zipcode,
        
      },
      method: "get",
      cache: { ignoreCache: true },
    })
    .then(({ data }) => {
      if (data.redirect) return;

      if (data.success) {
        dispatch({
          type: ORDER_GET_ORDERS_SUCCESS,
          data: data.data,
          review: data.message,
        });

      } else {
        dispatch({ type: ORDER_GET_ORDERS_FAIL });
        toast.warn(data.message);
      }
    });
};

export const ORDER_GET_ORDER_REQUEST = "ORDER_GET_ORDER_REQUEST";
export const ORDER_GET_ORDER_SUCCESS = "ORDER_GET_ORDER_SUCCESS";
export const ORDER_GET_ORDER_FAIL = "ORDER_GET_ORDER_FAIL";
export const getOrder = ({ order_id }) => (dispatch) => {
  dispatch({ type: ORDER_GET_ORDER_REQUEST });
  axios
    .request({
      url: "ma/order/online",
      params: { order_id },
      method: "get",
      cache: {
        ignoreCache: true,
      },
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: ORDER_GET_ORDER_SUCCESS, data: data.data });
      } else {
        dispatch({ type: ORDER_GET_ORDER_FAIL });
        toast.warn(data.message);
      }
    });
};

export const ORDER_CLEAR_ORDER_REQUEST = "ORDER_CLEAR_ORDER_REQUEST";
export const clearOrder = () => (dispatch) => {
  dispatch({ type: ORDER_CLEAR_ORDER_REQUEST });
};

export const ORDER_CHANGE_STATUS_REQUEST = "ORDER_CHANGE_STATUS_REQUEST";
export const ORDER_CHANGE_STATUS_SUCCESS = "ORDER_CHANGE_STATUS_SUCCESS";
export const ORDER_CHANGE_STATUS_FAIL = "ORDER_CHANGE_STATUS_FAIL";
export const changeOrderStatus = ({
  order_id,
  status,
  prep_time,
  reason_to_cancel,
  refund,
  access_code,
  ordersParams,
}) => (dispatch) => {
  dispatch({ type: ORDER_CHANGE_STATUS_REQUEST });
  if (refund) {
    axios
      .request({
        url: "ct/order/online/refund",
        params: { order_id, amount: refund, reason_to_cancel },
        method: "post",
      })
      .then(({ data }) => {
        if (data.redirect) return;
        if (data.success) {
          dispatch({ type: ORDER_CHANGE_STATUS_SUCCESS });
          dispatch(getOrder({ order_id }));
          if (ordersParams) {
            dispatch(getOrders(ordersParams));
          }
        } else {
          dispatch({ type: ORDER_CHANGE_STATUS_FAIL, errors: data.errors });
          toast.warn(data.message);
        }
      });
  } else {
    axios
      .request({
        url: "ct/order/online/status/change",
        data: {
          order_id,
          status,
          prep_time,
          reason_to_cancel,
          refund,
          access_code,
        },
        method: "post",
      })
      .then(({ data }) => {
        if (data.redirect) return;
        if (data.success) {
          dispatch({ type: ORDER_CHANGE_STATUS_SUCCESS });
          dispatch(getOrder({ order_id }));
          if (ordersParams) {
            dispatch(getOrders(ordersParams));
          }
        } else {
          dispatch({ type: ORDER_CHANGE_STATUS_FAIL, errors: data.errors });
          toast.warn(data.message);
        }
      });
  }
};

export const ORDER_GET_STATS_REQUEST = "ORDER_GET_STATS_REQUEST";
export const ORDER_GET_STATS_SUCCESS = "ORDER_GET_STATS_SUCCESS";
export const ORDER_GET_STATS_FAIL = "ORDER_GET_STATS_FAIL";
export const getOrderStats = ({ location_id, begin_time, end_time }) => (
  dispatch
) => {
  dispatch({ type: ORDER_GET_STATS_REQUEST });
  axios
    .request({
      url: "ct/orders/online/stats",
      params: { location_id, begin_time, end_time },
      method: "get",
      cache: {
        ignoreCache: true,
      },
    })
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: ORDER_GET_STATS_SUCCESS, data: data.data });
      } else {
        dispatch({ type: ORDER_GET_STATS_FAIL });
      }
    });
};
