import React from "react";
import {
  Card, Table, Row, Col, Form, Input, CardHeader, Modal, ModalBody, Container,
} from "reactstrap";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { capitalize } from "../../utils/tools";
import { DebounceInput } from 'react-debounce-input';
import ReactSelect from 'react-select';
import moment from "moment";

function MainCard(props) {
  const renderOrders = () => {
    let { orders } = props;
    if (!orders) return null;
    if (!orders.data) return null;
    if (orders.data.length === 0) return null;
    let renderColor = (status) => {
      let color = "";
      switch (status) {
        case "New":
          color = "#FFD600";
          break;
        case "Confirmed":
          color = "#FF9800";
          break;
        case "Canceled":
          color = "#FF553E";
          break;
        case "Ready":
          color = "#008DFF";
          break;
        default:
          color = "#03C9A9";
      }

      return color;
    };
    return orders.data.map((order, key) => {
      let type = order.type
        ? order.type.charAt(0).toUpperCase() + order.type.slice(1)
        : "N/A";
      let status = order.status;

      switch (status) {
        case "done":
          status = "Complete";
          break;
        case "placed":
          status = "New";
          break;
        default:
          status = capitalize(status);
          break;
      }

      return (
        <tr
          key={key}
          onClick={(e) =>
            props.seeOrderDetail(order.id)
          }
        >
          <td>#{order.id}</td>
          <td>
            <Container className="container">
              <div style={{ background: renderColor(status), width: '13px', height: '13px', float: "left", borderRadius: "4px", marginRight: "8px", marginLeft: "0px" }}>
              </div>
            </Container>
            {status}
          </td>
          <td>{order.brand_name}</td>
          <td>{order.location.name}</td>
          <td>{moment(order.created_at).format("MMM DD, hh:mm A")}</td>
          <td>{moment(order.request_at).format("MMM DD, hh:mm A")}</td>
          <td>{type}</td>
          <td>${order.total}</td>
          <td style={{ fontSize: 20, textAlign: "center", lineHeight: 1 }}>
            <i className="fal fa-angle-right"></i>
          </td>
        </tr>
      );
    });
  };

  const renderDateRange = () => {
    let { formData } = props;
    let ranges = [
      {
        startDate: formData.dateRange[0],
        endDate: formData.dateRange[1],
        key: "dateRange",
      },
    ];

    const renderPicker = (e) => {
      e.preventDefault();

      props.toggleModal(true);
    };

    const onChange = (e) => {
      let { dateRange } = e;
      props.onChange("dateRange", [dateRange.startDate, dateRange.endDate]);
    };

    return (
      <>
        <Input
          className="fillter-input"
          type="text"
          placeholder="DATE"
          value={
            format(props.formData.dateRange[0], "MM/dd/yy") +
            " - " +
            format(props.formData.dateRange[1], "MM/dd/yy")
          }
          name="startDate"
          onClick={(e) => renderPicker(e)}
          onChange={(e) => null}
        />
        <Modal
          isOpen={props.modalOpen}
          centered={true}
          className="date-range-modal"
          toggle={(e) => props.toggleModal(false)}
        >
          <ModalBody className="d-flex justify-content-center p-0">
            <DateRange
              onChange={(e) => onChange(e)}
              ranges={ranges}
              direction="horizontal"
              maxDate={new Date()}
              months={2}
              rangeColors={["#03c9a9"]}
              preview={{ color: "#03c9a9" }}
            />
          </ModalBody>
        </Modal>
      </>
    );
  };

  const brands = () => {
    let { allBrands } = props;
    if (!allBrands) return [];
    // if (allBrands.length === 0) return [{ value: '', label: 'Loading' }];
    let brands = allBrands.map(item => ({
      value: item.id,
      label: item.name
    })
    );
    let placeholder = [{ value: '', label: 'All Brands' }];
    brands = [...placeholder, ...brands];
    return brands;
  };



  return (
    <Card className="main-card">
      <CardHeader>
        <Form>
          <Row>
            <Col>
              <DebounceInput

                className="fillter-input"
                placeholder="KEYWORD"
                value={props.formData.keyword}
                minLength={1}
                debounceTimeout={500}
                onChange={(e) => props.onChange("keyword", e.target.value, true)}
              />
            </Col>
            <Col>
              <ReactSelect
                className=".fillter-input-content "
                placeholder="BRANDS"
                value={props.formData.selectedBrand}
                name="all_brands"
                options={brands()}
                isLoading={props.getBrandsRequest}
                classNamePrefix="BRANDS"
                onChange={(value) => props.onChange("selectedBrand", value, true)}
                onInputChange={(value) => props.onChange("brand_name", value, true)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#03c9a9",
                    primary25: "#eaeaea",
                  },
                })}
              />
            </Col>
            <Col>
              <DebounceInput
                className="fillter-input"
                placeholder="LOCATION ID"
                value={props.formData.location_id}
                minLength={1}
                debounceTimeout={500}
                onChange={(e) => props.onChange("location_id", e.target.value, true)}
              />
            </Col>
            <Col>
              <DebounceInput
                className="fillter-input"
                placeholder="ZIPCODE"
                value={props.formData.zipcode}
                minLength={1}
                debounceTimeout={500}
                onChange={(e) => props.onChange("zipcode", e.target.value, true)}
              />
            </Col>

            <Col>
              {renderDateRange()}
            </Col>
          </Row>
        </Form>
      </CardHeader>

      <Table responsive hover>
        <thead>
          <tr>
            <th>ORDER#</th>
            <th>STATUS</th>
            <th>BRAND</th>
            <th>LOCATION</th>
            <th>SUBMIT</th>
            <th>REQUEST</th>
            <th>TYPE</th>
            <th>TOTAL</th>
            <th style={{ width: 90 }}>DETAIL</th>
          </tr>
        </thead>
        <tbody>
          <tr onClick={(e) => props.seeOrderDetail(134)}></tr>
          {renderOrders()}
        </tbody>

      </Table>
      {props.renderPagination()}
    </Card>

  );
}


export default MainCard;


