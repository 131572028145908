import React from "react";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { login } from "../../store/actions/auth";
import LoginCard from "../../components/auth/LoginCard";
// import { TOKEN_KEY } from "../../utils/constant";



class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
      },
    };
    this.login = this.login.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    let hasToken = Cookies.get("access_token");
    if (hasToken) {
      let { from } = this.props.location.state || { from: { pathname: "/" } };
      this.props.history.replace(from);
    }
  }
  componentDidUpdate(prevProps) {
   
  }

  static getDerivedStateFromProps(props, state) {
    if (props.auth.profile) {
      let { from } = props.location.state || { from: { pathname: "/" } };
      props.history.replace(from);
    }
    return state;
  }

  login(e) {
    e.preventDefault();

    let { from } = this.props.location.state ||{ from: { pathname: "/" } };

    let { email, password, } = this.state;
    this.props.login({ email, password, from });
  }


  onInputChange(name, val) {
    this.setState({
      [name]: val,
    });
  }


  render() {
    let { loginRequest } = this.props.auth;
    return <LoginCard
      login={this.login}
      data={this.state}
      requesting={loginRequest}
      onInputChange={this.onInputChange}
    />;
  }
}




const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => dispatch(login(params)),
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);