import React from "react";
import Cookies from "js-cookie";
import { Route, Redirect } from "react-router-dom";
import DashboardLayout from "./DashboardLayout";
import { TOKEN_KEY } from "../../utils/constant";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

class DashboardLayoutRoute extends React.Component {
  render() {
    // location: the href
    const { component: Component, location, ...rest } = this.props;
    let hasToken = Cookies.get(TOKEN_KEY);

    return (
      <Route
        {...rest}
        render={(props) =>
          hasToken ? (
            <DashboardLayout {...props}>
              <Component {...props} />
            </DashboardLayout>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}

export default DashboardLayoutRoute;
